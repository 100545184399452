import Logo from '#images/common/logo.svg';
import { useEffect, useState } from 'react';
import { prepareUrl } from '#utils/prepareUrl';

type Props = {
  currentLanguage: string;
  style: string;
};
const testPaths = ['/pri4in', '/pri4tr', '/pri5in', '/pri10in'];

const LogoLink = ({ currentLanguage, style }: Props) => {
  const [logoLink, setLogoLink] = useState('');

  useEffect(() => {
    const location = window.location.pathname;
    const matchedPath = testPaths.find((path) => location.includes(path)) || '/';
    setLogoLink(prepareUrl(matchedPath, currentLanguage));
  }, []);

  return (
    <a aria-label="logo" href={logoLink} className={style}>
      <img src={Logo.src} height="32" width="140" alt="Detectico logo" loading="eager" />
    </a>
  );
};

export default LogoLink;
